import React from 'react';
import { Grid, CoreTypography, styled, Icon } from '@atlas-ui/core';
import { COLORS } from '@wu-ui/common';

import { EMAILS, URLS } from './constants';

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: '40px',
  paddingBottom: '32px',
  paddingLeft: '108px',
  backgroundColor: '#0a0a0a',
  borderTop: `1px solid ${COLORS.BACKGROUND}`,
  '& .header__text': {
    fontFamily: 'acumin-pro',
    fontSize: '0.65rem',
    color: '#ddd',
  },
  '& .header__text--main': {
    fontSize: '1rem',
    marginBottom: '32px',
  },
  '& .header__text--link': {
    cursor: 'pointer',
    color: '#436c30',
    padding: '0 4px',
    textDecoration: 'none',
  },
  '& .header__logo--container': {
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.5',
    },
  },
  '& .header__icons': {
    color: COLORS.WHITE,
    cursor: 'pointer',
    fontSize: '2.875rem',
    '& .fa': {
      padding: '0 9px',
      verticalAlign: 'middle',
    },
    '.fa-youtube': {
      fontSize: '2.5rem',
    },
  },
  '& .no-padding': {
    padding: 0,
  },
  '& .fit-content': {
    width: 'fit-content',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
})) as typeof Grid;

const Footer = () => {
  const openURL = (url: string) => {
    window.open(url);
  };

  return (
    <StyledGrid
      id="wu-app_footer-container"
      container
      alignItems="center"
      justifyContent="space-evenly">
      <Grid
        className="header__logo--container"
        onClick={() => openURL(URLS.PORTAL_DASHBOARD)}>
        <img
          src="/images/land-o-lakes-logo.png"
          alt="Land O'Lakes Logo"
          width="170px"
        />
      </Grid>
      <Grid>
        <CoreTypography className="header__text--main header__text">
          If you have any questions or feedback about this website, please
          contact
          <CoreTypography
            className="header__text--main header__text  header__text--link"
            component="a"
            href={`mailto:${EMAILS.WU_CUSTOMER_SERVICE}`}>
            {EMAILS.WU_CUSTOMER_SERVICE}
          </CoreTypography>
        </CoreTypography>
        <CoreTypography className="header__text">
          ©2024 WinField United. All Rights Reserved.
          <CoreTypography
            className="header__text  header__text--link"
            component="span"
            onClick={() => openURL(URLS.LOL_PRIVACY_POLICY)}>
            Privacy Policy User Agreement
          </CoreTypography>
        </CoreTypography>
        <CoreTypography className="header__text">
          Products and services promoted on
          <CoreTypography
            className="header__text  header__text--link"
            component="span"
            onClick={() => openURL(URLS.WINFIELD_UNITED)}>
            www.winfieldunited.com
          </CoreTypography>
          are available in the United States only.
        </CoreTypography>
        <CoreTypography className="header__text">
          For information on these products and services in other territories,
          please contact
          <CoreTypography
            className="header__text  header__text--link"
            component="a"
            href={`mailto:${EMAILS.WU_CUSTOMER_SERVICE}`}>
            {EMAILS.WU_CUSTOMER_SERVICE}
          </CoreTypography>
        </CoreTypography>
        <CoreTypography
          className="header__text header__text--link no-padding fit-content"
          onClick={() => openURL(URLS.LOL_CALIFORNIA_SUPPLY_CHAINS_ACT)}>
          CA Supply Chain Transparency Act Link
        </CoreTypography>
      </Grid>
      <Grid className="header__icons">
        <Icon
          className="fa fa-facebook"
          data-testid="icon-facebook"
          onClick={() => openURL(URLS.WU_FACEBOOK)}
        />
        <Icon
          className="fa fa-youtube"
          data-testid="icon-youtube"
          onClick={() => openURL(URLS.WU_YOUTUBE)}
        />
        <Icon
          className="fa fa-linkedin"
          data-testid="icon-linkedin"
          onClick={() => openURL(URLS.WU_LINKEDIN)}
        />
      </Grid>
    </StyledGrid>
  );
};

export default Footer;
