import React from 'react';
import { Grid, Icon, styled } from '@atlas-ui/core';
import { COLORS } from '@wu-ui/common';
import { useAppContext } from '../../contexts/AppContext';

const StyledDashboardIcon = styled(Grid)(({ theme }) => ({
  color: COLORS.MAIN_BLUE,
  marginRight: '44px',
  cursor: 'pointer',
  '& .dashboard-arrows': {
    fontSize: '0.75rem',
    marginLeft: '8px',
  },
  '& .dashboard-icon': {
    fontSize: '1.3125rem',
  },
  [theme.breakpoints.down('sm')]: {
    color: COLORS.WHITE,
  },
}));

interface IProps {
  className?: string;
}

const DashboardIcon = ({
  className = 'dashboard-icon-wrapper',
}: IProps): JSX.Element => {
  const { navigation } = useAppContext();

  const onClick = () => {
    navigation.set(!navigation.show);
  };

  return (
    <StyledDashboardIcon
      container
      className={className}
      alignItems="center"
      width="auto"
      onClick={onClick}>
      <Icon className="fa fa-dashboard-icon dashboard-icon" />
      {navigation.show && (
        <Icon className="fa fa-chevron-up dashboard-arrows" />
      )}
      {!navigation.show && (
        <Icon className="fa fa-chevron-down dashboard-arrows" />
      )}
    </StyledDashboardIcon>
  );
};

export default DashboardIcon;
