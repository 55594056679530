import React, { FormEvent, useState } from 'react';
import { CoreButton, Grid, Icon, SearchBox, styled } from '@atlas-ui/core';
import { useAppContext } from '../../contexts/AppContext';
import { COLORS } from '@wu-ui/common';

const StyledGrid = styled(Grid)({
  '& .search-bar__form': {
    display: 'flex',
  },
  '& .search-bar__search-box': {
    width: '443px',
    justifyContent: 'center',
  },
  '& .end-adornment-icon': {
    display: 'none',
  },
  '& input.MuiInput-input': {
    fontSize: '1.125rem',
    padding: '5px 0 9px 62px',
  },
  '& div.MuiInputBase-root': {
    '&.Mui-focused': {
      border: `2px solid ${COLORS.WHITE}`,
    },
    '&:hover': {
      border: `2px solid ${COLORS.WHITE}`,
    },
  },
  '& .search-bar__button': {
    fontFamily: 'acumin-pro-condensed',
    fontWeight: '800',
    textTransform: 'none',
    padding: '7px 29px 8px 29px',
    border: 'none',
    '&:hover': {
      color: 'grey',
    },
  },
  '& .search-bar__arrow-icon': {
    color: COLORS.BUTTON_BACKGROUND_BLUE,
    position: 'relative',
    left: '45px',
    top: '10px',
    zIndex: '1',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
}) as typeof Grid;

const SearchBar = ({ className }: { className?: string }) => {
  const { wuSettings } = useAppContext();
  const [inputValue, setInputValue] = useState('');

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const baseUrl = wuSettings?.menu?.find(
      (menuItem: any) => menuItem?.code === 'documents',
    )?.url;

    if (baseUrl) {
      const url = new URL(baseUrl);
      url.searchParams.set('searchText', inputValue);
      window.open(url?.href);
    }
  };

  const clearInput = () => {
    setInputValue('');
  };

  return (
    <StyledGrid className={className}>
      <form className="search-bar__form" onSubmit={submitHandler}>
        <Icon
          className="search-bar__arrow-icon fa fa-arrow"
          onClick={clearInput}
        />
        <SearchBox
          className="search-bar__search-box"
          inputProps={{
            'data-testid': 'search-bar__test-id',
          }}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <CoreButton type="submit" className="search-bar__button">
          Search
        </CoreButton>
      </form>
    </StyledGrid>
  );
};

export default SearchBar;
