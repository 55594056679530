import { useState, useEffect } from 'react';

const useIs404Page = () => {
  const [is404, setIs404] = useState(false);

  useEffect(() => {
    const checkIf404 = () => {
      const is404Page = document.getElementsByClassName('404__page').length > 0;
      setIs404(is404Page);
    };

    // Initial check
    checkIf404();

    // Optionally, set up an observer to detect DOM changes
    const observer = new MutationObserver(checkIf404);
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, []);

  return is404;
};

export default useIs404Page;
