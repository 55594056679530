import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import { useAccount, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import {
  Loader,
  useErrorHandler,
  GlobalFetchingProvider,
  QUERY_LOCAL_LOADER,
} from '@wu-ui/common';

import {
  myLinksIntegrationClient as _MyLinksIntegrationClient,
  ApiClientConfig as ApiClientConfigMyLinks,
  Link,
} from '@atlas-ui/mylinks-integration-client';
import {
  SettingsClient as _SettingsClient,
  ApiClientConfig as ApiClientConfigSettings,
} from '@atlas-ui/settings-client';

import { IWUSettings } from '../models/WuSettings';

import { API_SUBURL, QUERYKEYS } from '../utils/constants';
import { APP_ROUTE_MAIN_PATHS } from '../utils/appRoutes';

export interface IAppContext {
  wuSettings: IWUSettings;
  token: string;
  setToken: (state: string) => React.Dispatch<React.SetStateAction<string>>;
  isSettingsSuccess: boolean;
  isMenuSettingsLoading: boolean;
  hasUserReqPermissions: (permissions: string[]) => boolean;
  myLinks: Link[];
  isMyLinksDataReady: boolean;
  aiSearch: {
    keyword: string;
    setKeyword: (state: string) => React.Dispatch<React.SetStateAction<string>>;
  };
  navigation: {
    show: boolean;
    set: (state: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
  };
  [key: string]: unknown;
}

const defaultState = {} as IAppContext;

const AppContext = React.createContext<IAppContext>(defaultState);

export const useAppContext = (): IAppContext => {
  return useContext<IAppContext>(AppContext);
};

const AppContextProvider = ({
  children,
  ...rest
}: PropsWithChildren<{
  [key: string]: unknown;
}>): JSX.Element => {
  const { onError } = useErrorHandler();

  const { inProgress } = useMsal();
  const account = useAccount();
  const history = useHistory();

  const [token, setToken] = useState('');
  const [wuSettings, setWuSettings] = useState<Record<string, any>>({});
  const [myLinks, setMyLinks] = useState<Link[]>([]);
  const [aiSearch, setAiSearch] = useState({
    keyword: '',
    setKeyword: (state: string) =>
      setAiSearch(prev => ({ ...prev, keyword: state })),
  });
  const [navigation, setNavigation] = useState({
    show: true,
    set: (state: boolean) => setNavigation(prev => ({ ...prev, show: state })),
  });

  const hasUserReqPermissions = useCallback(
    (permissions: string[]) => {
      const tokenClaims = account?.idTokenClaims?.security_roles as string[];

      return permissions.some(p => tokenClaims?.includes(p));
    },
    [account?.idTokenClaims],
  );

  const SettingsClient = useMemo(() => {
    if (!token) return;

    return new _SettingsClient(
      new ApiClientConfigSettings(token, process.env.X_VERSION_HEADER ?? ''),
      process.env.API_BASE_URL + API_SUBURL.SETTINGS ?? '',
    );
  }, [token]);

  const MyLinksClient = useMemo(() => {
    if (!token) return;

    return new _MyLinksIntegrationClient(
      new ApiClientConfigMyLinks(token, process.env.X_VERSION_HEADER ?? ''),
      process.env.API_BASE_URL + API_SUBURL.MY_LINKS ?? '',
    );
  }, [token]);

  const { isSuccess: isSettingsSuccess } = useQuery(
    ['wu-portal-ui-modules', token],
    async () => {
      if (!SettingsClient) return;

      const result = await SettingsClient.getSetting('wu-portal-ui-modules');

      setWuSettings(prev => ({ ...prev, ...result.settings }));
    },
    {
      enabled: !!SettingsClient && !!token,
      onError,
    },
  );

  const { isError: isMyLinksError, isSuccess: isMyLinksSuccess } = useQuery(
    [QUERYKEYS.GET_MY_LINKS, token, QUERY_LOCAL_LOADER],
    async ({ signal }) => {
      if (!MyLinksClient) return;

      const result = await MyLinksClient.link_GetMyLinks(signal);

      setMyLinks(result);
    },
    {
      enabled: !!MyLinksClient && !!token,
      onError,
    },
  );

  const { isSuccess: isMenuSettingsLoading } = useQuery(
    ['wu-portal-ui-menu', token],
    async () => {
      if (!SettingsClient) return;

      const result = await SettingsClient.getSetting('wu-portal-ui-menu');

      setWuSettings(prev => ({ ...prev, ...result.settings }));
    },
    {
      enabled: !!SettingsClient && !!token,
      onError,
    },
  );

  // Reset the search term when the user navigates away from the search results page
  useEffect(() => {
    history.listen(() => {
      if (
        history.location.pathname === APP_ROUTE_MAIN_PATHS.SEARCH ||
        aiSearch.keyword === ''
      )
        return;

      setAiSearch(prev => ({ ...prev, keyword: '' }));
    });
  }, [history, aiSearch.keyword]);

  const state = {
    token,
    setToken,
    wuSettings,
    isSettingsSuccess,
    isMenuSettingsLoading,
    hasUserReqPermissions,
    myLinks,
    isMyLinksDataReady: isMyLinksSuccess || isMyLinksError,
    aiSearch,
    navigation,
    ...rest,
  } as IAppContext;

  return (
    <AppContext.Provider value={state}>
      <GlobalFetchingProvider>
        {isQueryingGlobal => (
          <>
            <Loader
              showLoader={
                isQueryingGlobal || inProgress !== InteractionStatus.None
              }
            />
            {children}
          </>
        )}
      </GlobalFetchingProvider>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
