import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  Autocomplete,
  CoreButton,
  Grid,
  Icon,
  SearchBox,
  styled,
} from '@atlas-ui/core';
import {
  ApiClientConfig,
  AutocompleteMode,
  searchClient as _SearchClient,
} from '@atlas-ui/search-client';
import {
  COLORS,
  QUERY_LOCAL_LOADER,
  Toast,
  ToastModule,
  ToastNotifyType,
  useErrorHandler,
} from '@wu-ui/common';

import { useAppContext } from '../../contexts/AppContext';
import { API_SUBURL, QUERYKEYS } from '../../utils/constants';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .search-bar__form': {
    display: 'flex',
    maxWidth: '544px',
  },
  '& .search-bar__search-box': {
    width: '443px',
  },
  '& .MuiInput-root .MuiInput-input': {
    fontSize: '1.125rem',
    padding: '7px 0',
  },
  '& div.MuiInputBase-root': {
    border: '2px solid #F6F4EF',
    '&.Mui-focused': {
      border: `2px solid ${COLORS.WHITE}`,
    },
    '&:hover': {
      border: `2px solid ${COLORS.WHITE}`,
    },
  },
  '& .search-bar__button': {
    fontFamily: 'acumin-pro-condensed',
    fontWeight: '800',
    textTransform: 'none',
    padding: '7px 29px 8px 29px',
    border: 'none',
    '&:hover': {
      color: 'grey',
    },
  },
  '& .search-bar__arrow-icon': {
    color: COLORS.BUTTON_BACKGROUND_BLUE,
    paddingLeft: '4px',
    paddingRight: '18px',
    marginLeft: '12px',
    borderLeft: '2px solid #F6F4EF',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
  '& .MuiAutocomplete-paper': {
    borderRadius: '0',
    boxShadow: '0px 3px 6px #00000029',
    width: '544px',
    marginLeft: '-2px',
  },
  '& .MuiAutocomplete-option': {
    color: theme.palette.primary.main,
  },
})) as typeof Grid;

const AiSearchBar = ({ className }: { className?: string }) => {
  const { onError } = useErrorHandler();
  const { token } = useAppContext();

  const [openSuggester, setOpenSuggester] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const history = useHistory();

  const SearchClient = useMemo(() => {
    if (!token) return;

    return new _SearchClient(
      new ApiClientConfig(token, process.env.X_VERSION_HEADER ?? ''),
      process.env.API_BASE_URL + API_SUBURL.SEARCH ?? '',
    );
  }, [token]);

  const {
    data: suggestedOptions,
    isFetching,
    refetch: refetchSuggestions,
  } = useQuery(
    [QUERYKEYS.GET_SUGGESTED_OPTIONS, QUERY_LOCAL_LOADER, token],
    async () => {
      if (!SearchClient) return;

      const result = await SearchClient.autocomplete(
        searchValue,
        false,
        5,
        searchValue?.trim().includes(' ')
          ? AutocompleteMode.OneTermWithContext
          : AutocompleteMode.TwoTerms,
      );

      return result;
    },
    {
      enabled: false,
      onError,
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSuggester = useCallback(
    debounce(searchValue => {
      if (searchValue.trim().length >= 3) refetchSuggestions();
    }, 500),
    [searchValue],
  );

  useEffect(() => {
    debounceSuggester(searchValue);

    return () => {
      debounceSuggester.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (searchValue.trim().length < 3) {
      ToastModule.toast.custom(
        t => (
          <Toast
            type={ToastNotifyType.Error}
            message="Please enter 3 or more characters to begin your search"
            toastTitle="Search Error"
            visible={t.visible}
            style={{
              marginTop: '60px',
            }}
          />
        ),
        {
          id: 'search-error-toast',
          position: 'top-center',
        },
      );

      return;
    }

    ToastModule.toast.dismiss('search-error-toast');
    // add the search keyword to the query params
    history.push(`/search?keyword=${encodeURI(searchValue.trim())}`);
  };

  const onSearchInputValueChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
  ) => {
    setSearchValue(value === null ? '' : value);
  };

  const onSuggesterOpen = () => {
    if (searchValue.length + 1 >= 3) setOpenSuggester(true);
  };

  const clearInput = () => {
    setSearchValue('');
  };

  return (
    <StyledGrid className={className}>
      <form className="search-bar__form" onSubmit={submitHandler}>
        <Autocomplete
          value={searchValue}
          options={suggestedOptions ?? []}
          inputValue={searchValue}
          onInputChange={onSearchInputValueChange}
          freeSolo
          open={openSuggester}
          onOpen={onSuggesterOpen}
          onClose={() => {
            setOpenSuggester(false);
          }}
          loading={isFetching}
          disablePortal
          filterOptions={options => options}
          renderInput={params => (
            <SearchBox
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Icon
                    className="search-bar__arrow-icon fa fa-arrow"
                    onClick={clearInput}
                    data-testid="arrow-icon"
                  />
                ),
                endAdornment: false,
                disableUnderline: true,
              }}
              className="search-bar__search-box"
              inputProps={{
                ...params.inputProps,
                'data-testid': 'search-bar__test-id',
              }}
            />
          )}
        />
        <CoreButton type="submit" className="search-bar__button">
          Search
        </CoreButton>
      </form>
    </StyledGrid>
  );
};

export default AiSearchBar;
